import React from 'react'
import CabaretLayout from '../../../components/cabaretlayout'
import CabaretInfosNavigation from '../../../components/Infos/CabaretInfos/CabaretInfosNavigations'
import { StaticImage } from 'gatsby-plugin-image'

const CabaretInfosHebergements = () => (
    <CabaretLayout>
        <div className='cabaret-infos'>

            <CabaretInfosNavigation />

            <h1>Hébergement</h1>

            <div className='cabaret-infos-inner'>

                <h2>Auberge des balcons</h2>
                <h3>Blottie entre les murs de Maison Mère, un ancien couvent chargé d’histoire, l’Auberge des Balcons se démarque par son caractère authentique, son accueil sympathique et son ambiance vibrante.</h3>
                <h3>Le Bistro représente un lieu de diffusion culturelle porteur de vie et de beaux moments d'échanges autant pour les locaux que les visiteurs de passage!</h3>

                <StaticImage alt='Auberge des balcons' className="max-w-[1856px] max-h-[1044px] my-4" imgClassName='rounded-3xl' src="../../../images/cabaret/aubergedesbalcons.png" />

                <h3>L'Auberge propose différentes options d'hébergement à partir de 24 $ (+ taxes) en dortoir et à partir de 59 $ (+ taxes) chambre privée, des espaces communs rénovés (salons-cuisine, salles de bain) et finalement de grands balcons avec une vue spectaculaire sur le Chemin des Soeurs, les montagnes environnantes et le fleuve au loin.</h3>

                <h3>Le Bistro des Balcons quant à lui est accessible à tous, locaux et visiteurs. Le bar et la cuisine sont ouverts tous les jours de 16h à 23h. Il y a des spectacles de musique chaque fin de semaine et une foule d'autres événements ponctuels variés annoncés sur la <a href="https://www.facebook.com/aubergedesbalcons" target='_blank' rel="noreferrer">page Facebook</a>. La cuisine offre un menu simple et réconfortant avec options végétariennes. C'est un espace multi-générationnel où tous les âges sont conviés.</h3>
                <form action="https://aubergedesbalcons.com/" target='_blank'><button type='submit'>Réserver</button></form>
            </div>

        </div>
    </CabaretLayout>
)

export default CabaretInfosHebergements
